import {React, Component , useState} from "react";
// import './Portfolio.css';
import Fade from "react-reveal";
import { useReactPWAInstall } from '@amtpub/react-pwa-install';
import { platforms, getPlatform } from "./pwa-install/Platforms";
import IOSShareIcon from "./pwa-install/IOSShareIcon";
import IOSHomeScreenIcon from "./pwa-install/IOSHomeScreenIcon";
import { Container, Offcanvas, Modal, Row, Col, Nav } from 'react-bootstrap';
import { isMobile } from "mobile-device-detect";

const platform = getPlatform();

const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);

function logger(message) {
    if (true) {
        console.log(message);
    }
}

export function isInstalled() {
  if (window.navigator.standalone === true || window.matchMedia("(display-mode: standalone)").matches) {
      logger("isInstalled: true. Already in standalone mode");
      return true;
  }
  logger("isInstalled: false.");
  return false;
}

export function supported() {
  if (platform === platforms.NATIVE) {
      logger("supported: true - native platform 1");
      return true;
  }
  if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      logger("supported: true - manual support 2");
      return true;
  }
  logger("supported: false");
  return false;
}

function Portfolio(props) {
  const { pwaInstall, supported2, isInstalled2 } = useReactPWAInstall();
  const [showInstall, setShowInstall] = useState(false);

    const handleCloseInstall = () => setShowInstall(false);
    const handleShowInstall = () => setShowInstall(true);


    const handleClick = () => {
       //handleShowInstall();

        platform !== platforms.IDEVICE && pwaInstall({
            title: "drMakowski",
            // logo: myLogo,
            features: (
                <ul>
                    <li>Posiada certyfikat bezpieczeństwa.</li>
                    <li>Szybko się ładuje i działa również w trybie offline.</li>
                    <li>Możesz szybko i bez ograniczeń udostępniać dowolnej liczbie osób.</li>
                </ul>
            ),
            featuresTitle: "Twoja strona internetowa w wygodnej aplikacji:",
            description: "Pobierz i przekonaj się sam jakie to proste i wygodne narzędzie do komunikacji z klientami lub pracownikami",
            descritpionTitle: " ",
            instructionActionInstall: "Zainstaluj",
            instructionActionCancel: "Anuluj",
            instructionActionOK: "OK",
        })
            // .then(() => alert("App installed successfully or instructions for install shown"))
            .catch(() => alert("Szkoda, że nie zdecydowałe/aś się na pobranie aplikacji"));

        platform === platforms.IDEVICE && handleShowInstall();
    };

  return (
    <section id="portfolio">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns">
            <h1>Szybki kontakt:</h1>
            <h2>tel. 502 551 404</h2>
            <p>kontakt@usgrobertmakowski.pl</p>
            {supported() && !isInstalled() && (
              <>
                <div>
                  {/* <img className="img-fluid" id="icon" src="192.png"></img> */}
                  <button onClick={handleClick}>Pobierz aplikację na telefon >></button>
                </div>
                <DialogActionWithInstructions2 showInstall={showInstall} handleCloseInstall={handleCloseInstall} />
              </>
            )}
            {/* <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </div> */}
          </div>
        </div>
      </Fade>
    </section>
  );
}

function DialogActionWithInstructions2(props) {
  return (
      <Modal show={props.showInstall} onHide={props.handleCloseInstall} size='lg' animation={true} centered>
          <Modal.Header>
              <Modal.Title>Pobierz aplikację:</Modal.Title>
          </Modal.Header>
          <Modal.Body >
              <Container>
                <Row>
                  <Col>
                    1. Naciśnij <IOSShareIcon className="pwaPromptShareIcon" modern={isiOS13AndUp} /> na dole ekranu.
                  </Col>
                </Row>
                <Row>
                  <Col>
                    2. Naciśnij <IOSHomeScreenIcon className={"pwaPromptHomeIcon"} modern={isiOS13AndUp} /> i dodaj do ekranu początkowego.
                  </Col>
                </Row>
              </Container>
          </Modal.Body>
      </Modal>
  );
}

export default Portfolio;
