import React, { Component } from "react";
import Fade from "react-reveal";

import { WhatsappShareButton, EmailShareButton, WhatsappIcon, EmailIcon, FacebookMessengerShareButton, FacebookMessengerIcon } from 'react-share';
import IOSShareIcon from "./pwa-install/IOSShareIcon";
import IOSHomeScreenIcon from "./pwa-install/IOSHomeScreenIcon";

import QRCode from 'qrcode.react';

class Testimonials extends Component {
  render() {
    // if (!this.props.data) return null;

    // const testimonials = this.props.data.testimonials.map(function(testimonials) {
    //   return (
    //     <li key={testimonials.user}>
    //       <blockquote>
    //         <p>{testimonials.text}</p>
    //         <cite>{testimonials.user}</cite>
    //       </blockquote>
    //     </li>
    //   );
    // });

    return (
      <section id="testimonials">
        <Fade left duration={1000} distance="40px">
          {/* <div className="row"> */}
            {/* <div className="twelve columns"> */}
              {/* <img style={{width:60}} src="apka.svg"></img> */}
            {/* <button>Pobierz aplikację na telefon >></button>
          </div> */}
          {/* </div> */}
          
            <div className="twelve columns">
              <h2>Udostępnij aplikację znajomym:</h2>
            </div>
            
            <div className="twelve columns">
            <QRCode value="https://usgrobertmakowski.pl" size={200} />
            </div>
          
            <div className="twelve columns">
              <h2>lub</h2>
            </div>
          
            <div className="twelve columns">
              {/* <div> */}
                <WhatsappShareButton url='https://usgrobertmakowski.pl' body='Przesyłam Ci link do mojej strony z aplikacją na telefon:'>
                <WhatsappIcon size='100' borderRadius={5} />
                </WhatsappShareButton>
              {/* </div>
              <div>   */}
                <EmailShareButton subject='dr Robert Makowski' url='https://usgrobertmakowski.pl' body='Przesyłam Ci link do mojej strony z aplikacją na telefon:'>
                <EmailIcon size='100' borderRadius={5} />
                </EmailShareButton>
              {/* </div>
              <div> */}
                <FacebookMessengerShareButton subject='dr Robert Makowski' url='https://usgrobertmakowski.pl' body='Przesyłam Ci link do mojej strony z aplikacją na telefon:'>
                <FacebookMessengerIcon size='100' borderRadius={5} />
                </FacebookMessengerShareButton>  
              {/* </div> */}
            </div>
            {/* </div>
            <div className="row">
            <div className="ten columns flex-container">
              <ul className="slides">{testimonials}</ul>
            </div> */}
        </Fade>
      </section>
    );
  }
}

export default Testimonials;
