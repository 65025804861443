import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    // if (!this.props.data) return null;

    // const name = this.props.data.name;
    // const profilepic1 = "images/" + this.props.data.image1;
    // const profilepic2 = "images/" + this.props.data.image2;
    // const bio1 = this.props.data.bio1;
    // const bio2 = this.props.data.bio2;
    // const street = this.props.data.address.street;
    // const city = this.props.data.address.city;
    // const state = this.props.data.address.state;
    // const zip = this.props.data.address.zip;
    // const phone1 = this.props.data.phone1;
    // const email1 = this.props.data.email1;
    // const phone2 = this.props.data.phone2;
    // const email2 = this.props.data.email2;
    // const resumeDownload1 = this.props.data.resumedownload1;
    // const resumeDownload2 = this.props.data.resumedownload2;


    return (
      <section id="about">
        {/* <Fade duration={1000}> */}
          <div className="row">
            <div className="tweleve columns">
              <img
                className="profile-pic"
                src="fotka.png"
                alt="dr Robert Makowski"
              />
            </div>
            {/* <div className="nine columns main-col"> */}
              {/* <h2>dr Robert Makowski</h2>
              <h3>spejalista ginekolog</h3> */}
              {/* <div className="row">
                <div className="columns contact-details">
                  {/* <h2>Agnieszka Rokowska</h2> */}
                  {/* <p className="address"> */}
                    {/* <span>{name}</span>
                    <br /> */}
                    {/* <span>
                      {street} {zip} {city} {state}
                    </span>
                    <br /> */}
                    {/* <span>{phone1}</span>
                    <br />
                    <span>{email1}</span>
                  </p>
                </div>
                <div className="columns download">   */}
                {/* <img
                  src="qrcode.png"
                  alt="Nordic Giant Profile Pic"
                /> */}
                {/* <a href={resumeDownload1} className="button">
                  <i></i>Pobierz eWizytówkę >>
                </a>
                </div> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        {/* </Fade> */}
        {/* <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic2}
                alt="Artur Bizoń"
              />
            </div>
            <div className="nine columns main-col">
              <h2>Artur Bizoń</h2> 
              <p>{bio2}</p>
              <div className="row">
                <div className="columns contact-details">
                  {/* <h2>Agnieszka Rokowska</h2> */}
                  {/* <p className="address"> */}
                    {/* <span>{name}</span>
                    <br /> */}
                    {/* <span>
                      {street} {zip} {city} {state}
                    </span> */}
                    {/* <span>{phone2}</span>
                    <br />
                    <span>{email2}</span>
                  </p>
                </div>
                <div className="columns download">   */}
                {/* <img
                  src="qrcode.png"
                  alt="Nordic Giant Profile Pic"
                /> */}
                {/* <a href={resumeDownload2} className="button">
                  <i></i>Pobierz eWizytówkę >>
                </a>
                </div>
              </div>
            </div>
          </div>
        </Fade> */}
      </section>
    );
  }
}

export default About;
